<template>
  <v-card v-if="!loading && pendingRequests.length" class="mb-9" shaped outline elevation="0">
    <v-toolbar dense flat>
      <v-badge bordered bottom color="secondary" :content="pendingRequests.length" :value="pendingRequests.length > 0"
               offset-x="12"
               offset-y="15"
      >
        <v-avatar size="35" color="primary">
          <font-awesome-icon size="lg" class="white--text" :icon="['fad', 'user-plus']" />
        </v-avatar>
      </v-badge>
      <v-toolbar-title class="ml-4 mt-1 font-weight-light">{{ 'Invitation' | pluralize(pendingRequests.length) }} {{ ' reçue' | pluralize(pendingRequests.length) }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />


    <template v-if="pendingRequests.length">
      <team-list-item v-for="item in pendingRequests" :key="item" :team-uid="item" />
    </template>
  </v-card>
</template>

<script>
  import * as Invitations from '@/modules/teams/api'
  import {Space, Team} from '@/models'
  import TeamListItem from '@/modules/teams/components/TeamListItem'
  import {column} from '@/utils/array'

  export default {
    name: 'TeamPendingRequestsList',
    components: {TeamListItem},
    data() {
      return {
        pendingRequests: [],
        loading: true
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      team() {
        return uid => Team.get(uid)
      }
    },
    async created() {
      await this.onLoad()
    },
    methods: {
      async onLoad() {
        try {
          this.loading = true
          let {list} = (await Invitations.list(this.space.uid, ['patient', 'user', 'total_members', 'total_guests'], {
            guest: this.$auth.user.sub
          })).data


          Team.save(list)
          this.pendingRequests = this.pendingRequests.concat(column(list, 'uid'))
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Une erreur est survenue veuillez réessayer.'
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
