<template>
  <div>
    <team-invitations-list />
    <v-card class="elevation-x mb-3 is-scroll-target">
      <v-sheet :color="$vuetify.theme.dark ? '' :'grey lighten-3'" class="pa-2">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="term" dense filled rounded placeholder="Recherche par nom" hide-details />
          </v-col>
          <!--          <v-col class="d-none d-md-block">-->
          <!--            <v-select dense filled rounded multiple placeholder="Statut du groupe" :items="sortingTypes" hide-details />-->
          <!--          </v-col>-->
        </v-row>
      </v-sheet>
      <v-divider />
      <q-infinite-scroll ref="is" :offset="500" scroll-target=".is-scroll-target" @load="onLoad">
        <template v-if="items.length">
          <team-list-item v-for="item in items" :key="item" :team-uid="item" go-to-profile @click.stop="$emit('input', item)" />
        </template>
        <template v-else-if="!loading">
          <empty-list v-if="items.length === 0 && !loading" title="Aucun résultat"
                      subtitle="Vous n'avez aucun groupe dans votre liste" actions="Créer un groupe" @action-empty="createTeam"
          />
        </template>
        <template v-slot:loading>
          <skeleton-list :small="items.length > 0" />
        </template>
      </q-infinite-scroll>
    </v-card>
  </div>
</template>

<script>
  import EmptyList from '@/modules/core/layout/EmptyList'
  import {Space, Team} from '@/models'
  import TeamListItem from '@/modules/teams/components/TeamListItem'
  import TeamInvitationsList from '../components/TeamPendingRequestsList'
  import * as Teams from '@/modules/teams/api'
  import {debounce} from 'throttle-debounce'
  import {column} from '@/utils/array'
  import SkeletonList from '@/modules/core/layout/SkeletonList'
  import {QInfiniteScroll} from 'quasar'

  export default {
    name: 'TeamsList',
    components: {TeamListItem, EmptyList, TeamInvitationsList, SkeletonList, QInfiniteScroll},
    data() {
      return {
        loading: false,
        sortingTypes: ['Groupes actifs', 'Groupes cloturés'],
        items: [],
        previousTerm: '',
        term: ''
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      team() {
        return uid => Team.get(uid)
      }
    },
    watch: {
      term: debounce(300, async function (v) {
        let term = v.length >= 3 ? v : ''
        if (this.previousTerm !== term) {
          this.previousTerm = term
          this.items = []
          this.$refs.is.reset()
          this.$refs.is.resume()
        }
      })
    },
    methods: {
      async onLoad(index, done) {
        try {
          this.loading = true
          let searchTerm = this.term.length >= 3 ? this.term : null
          let {list, total} = (await Teams.list(this.space.uid, ['patient', 'user', 'total_members', 'total_guests'], {
            member: this.$auth.user.sub,
            name: searchTerm
          }, index, 10)).data

          let currentTerm = this.term.length >= 3 ? this.term : null
          if (currentTerm !== searchTerm) {
            done()
            return
          }

          Team.save(list)
          this.items = this.items.concat(column(list, 'uid'))
          done(this.items.length >= total)
        } catch (e) {
          this.$handleApiError(e)
          done(true)
        } finally {
          this.loading = false
        }
      },
      createTeam () {
        this.$root.$emit('modal:open', {
          modal: ()=> import('@/modules/teams/components/TeamForm')
        })
      }
    }
  }
</script>
