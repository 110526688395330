<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item :input-value="active" three-line active-class="selected-team" @click="onClick">
    <v-list-item-avatar>
      <v-img :src="avatar" />
    </v-list-item-avatar>

    <v-list-item-content class="team-active">
      <v-list-item-title>{{ team.name }}</v-list-item-title>
      <v-list-item-subtitle v-if="team.patient">
        Patient : <span class="secondary--text">{{ team.patient.full_name }}</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else>
        Groupe libre
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="team.status !== 'closed'" class="font-xs lh-10 pb-1 op-80">
        {{ formatTotalMembers }} - Créé
        <base-date :date="team.creation_date" />
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else class="font-xs lh-10 pb-1 op-80">
        Espace cloturé
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="invitation" class="align-center flex-row align-self-center">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :color="acceptingInvitation ? 'grey lighten-1' : ''" icon dark v-bind="attrs" :loading="acceptingInvitation" v-on="on" @click="acceptInvitation">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'check']" class="secondary--text" />
          </v-btn>
        </template>
        <span>Accepter l'invitation</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :color="decliningInvitation ? 'grey lighten-1' : ''" icon dark v-bind="attrs" :loading="decliningInvitation" v-on="on" @click="declineInvitation">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'times']" class="red--text text--darken-3" />
          </v-btn>
        </template>
        <span>Décliner l'invitation</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-else-if="!invitation && !selectable">
      <v-chip v-if="team.status === 'closed'" x-small color="grey" dark>
        Cloturé
      </v-chip>
      <v-chip v-else x-small color="success">
        Actif
      </v-chip>
    </v-list-item-action>
    <v-list-item-action v-else-if="selectable && active">
      <font-awesome-icon fixed-width size="lg" :icon="['fad', 'check-circle']" class="success--text" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import BaseDate from '@/modules/core/components/BaseDate'
  import {Invitation, Membership, Team} from '@/models'
  import {removeItem} from '@/utils/array'
  import darkAvatar from '@/assets/img/usergroup-dark.png'
  import lightAvatar from '@/assets/img/usergroup.png'

  let lightTeamImage = require('@/assets/img/usergroup.png')
  let darkTeamImage = require('@/assets/img/usergroup-dark.png')

  export default {
    name: 'TeamListItem',
    components: {BaseDate},
    props: {
      teamUid: String,
      goToProfile: Boolean,
      active: Boolean,
      selectable: Boolean
    },
    data() {
      return {
        lightTeamImage,
        darkTeamImage,
        acceptingInvitation: false,
        decliningInvitation: false
      }
    },
    computed: {
      avatar() {
        return this.$vuetify.theme.isDark ? darkAvatar : lightAvatar
      },
      team() {
        return Team.get(this.teamUid)
      },
      invitation() {
        return this.team.status !== 'closed' && this.team.invitation && (!this.membership || this.membership.elevation === 'none') ? Invitation.get(this.team.invitation) : null
      },
      membership() {
        return this.team.status !== 'closed' && this.team.membership ? Membership.get(this.team.membership) : null
      },


      formatTotalMembers() {
        if (this.team.total_members > 0) {
          return this.team.total_members + ' participant' + (this.team.total_members > 1 ? 's' : '')
        } else {
          return 'Aucun participant'
        }
      }
    },
    methods: {
      async acceptInvitation() {
        try {
          this.acceptingInvitation = true
          let {invitation, membership} = await this.invitation.accept()

          Membership.save({...membership, user: this.$store.state.core.user})
          this.team.$mutate((team) => {
            team.invitation = null
            team.membership = membership.uid
            ++team.total_members

            if (Array.isArray(team.memberships)) {
              team.memberships.push(membership.uid)
            }
            if (Array.isArray(team.memberships)) {
              removeItem(team.invitations, invitation.uid)
            }
          })
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Invitation acceptée',
            subtitle: 'Invitation acceptée avec succès.'
          })
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'L\'invitation n\'a pas pu être acceptée.'
          })
        } finally {
          this.acceptingInvitation = false
        }
      },
      async declineInvitation() {
        try {
          this.decliningInvitation = true
          await this.invitation.decline()
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'L\'invitation n\'a pas pu être déclinée.'
          })
        } finally {
          this.decliningInvitation = false
        }
      },
      onClick() {
        this.$emit('click')
        if(this.goToProfile) {
          if(!this.invitation){
            this.$router.push({name: 'team-profile', params: {uid: this.team.uid}})
          }
        }
      }
    }
  }
</script>
